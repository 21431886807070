.order-flow {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 2em 0;
    background-color: #E6F0EC;
    h1{ 
        margin: 2em 0;
    }
}