.quotation-form-desktop{
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
    h1 {
        font-weight: 700;
        font-size: 1.5rem;
        color: #135040;
        margin: 2em 0;
    }
    > h3 {
        font-weight: 700;
        font-size: 1rem;
        color: #135040
    }
    &__summary{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 40%;
        background-color: #e6f0ec;
        cursor: pointer;
        &__header{
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    &__contact{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 60%;
        h1{
            margin: 1em 0 0 5rem ;
            font-size: 3em;
        }
        h3{ 
            margin: 1em 5rem;
            font-size: 1.5rem;
        }
        &__form{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            &__name-container{
                display: flex;
                gap: 2em;
                width: 84%;
                > div{
                    margin: 1em 0;
                }
            }
            &__input {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                width: 80%;
                margin: 1em;
                padding: 1em 1em 0.7em 1em;
                background-color: #f5f5f5;
                border: solid 1px transparent;
                border-radius: 10px;
                gap: 0.5em;
                label{
                    font-size: 0.8rem;
                }
                input{
                    font-size: 1rem;
                    min-height: 1em;
                    background-color: #f5f5f5;
                    border: none;
                    width: 100%;
                }
                input:focus{
                    border: none;
                    outline: none;
                    width: 100%;
                    font-weight: bold;
                }
            }
            &__input:focus-within {
                border: #135040;
                outline-color: #135040;
                outline-style: solid;
            }
            &__button{
                display: flex;
                width: 84%;
                justify-content: space-between;
                align-items: center;
                button{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: solid 1px transparent;
                    border-radius: 10px;
                    color: white;
                    background-color: #135040;
                    padding: 1.4em 1em;
                    width: 40%;
                    gap: 1em;
                    font-size: 1em;
                    letter-spacing: 1px;
                    font-weight: 400;
                    img{
                        height:30px;
                    }
                }
                &__back{
                    span{
                        font-size: 1.5em;
                    }
                    h3{
                        margin: 0;
                    }
                    a{
                        display: flex;
                        gap: 1em;
                        align-items: center;
                        text-decoration: none;
                    }
                }
            }
            
        }
        
    }
}