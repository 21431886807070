.uvpContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10vh 5vw;
  gap: 20px;
}

.uvpList {
  flex: 1;
  text-align: center;
  &__title {
    font-weight: bold;
    font-size: 24px;
    color: #135040;
  }
  &__text {
    font-size: 15px;
    font-weight: 600;
    color: #7c7c7c;
  }
}

@media screen and (max-width: 640px) {
  .uvpContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10vh 15vw;
    gap: 20px;
  }

  .uvpList {
    flex: 1;
    text-align: center;
    &__title {
      font-weight: bold;
      font-size: 24px;
      color: #135040;
    }
    &__text {
      font-size: 14px;
      font-weight: 600;
      color: #7c7c7c;
    }
  }
}
