.footer {
    width: 100%;
    display: flex;
    gap: 5em;
    flex-direction: column;
    background-color: #f8f8f8;
    box-sizing: border-box;
    padding: 5vh 2vw;

    .logo-gosyen {
        margin-left: 4%;
    }

    .footer-menu-bar {
        display: flex;
        flex-direction: row;
        gap: 3em;
        margin-left: 4%;
    }

    .contact-copyright {
        display: flex;
        flex-direction: column;
        gap: 1em;
        margin-left: 4%;
    }

    a {
        text-decoration: none;
        color: black;
    }
}

.menu-title {
    color: #135040;
    font-weight: 700;
}

.menu-child {
    flex: 1;
    gap: 1em;
    display: flex;
    flex-direction: column;
}

#address {
    flex: 5;
}

.instagram-logo {
    display: flex;
    gap: 1em;
    align-items: center;
    flex-direction: row;
}

.menu-content p {
    margin: 0;
}
