body {
  margin: 0;
  font-family: "Albert Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  pointer-events: none;
}


@media screen and (max-width: 960px) {
  body{
    width: 100%;
  }
  
}
