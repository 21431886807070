.quotationModalContainer {
    background-color: #e6f0ec;
    padding: 5vh 2vw;
    box-sizing: border-box;
    position: fixed;
    width: 50vw;
    height: 100vh;
    right: 0;
    top: 0;
    z-index: 1000;
    transition: right 0.5s ease-in-out, opacity 0.5s ease-in-out;
    overflow: scroll;
}

.quotationModalContainerInactive {
    background-color: #e6f0ec;
    padding: 5vh 2vw;
    box-sizing: border-box;
    position: fixed;
    width: 45vw;
    height: 100vh;
    opacity: 0;
    right: -45vw;
    top: 0;
    z-index: 1000;
    transition: right 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.quotationModalHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #135040;
    font-size: 28px;
    &__title {
        color: #135040;
        font-weight: 600;
        span {
            font-weight: 400;
        }
    }
    &__closeBtn {
        color: #135040;
        font-weight: 600;
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: 28px;
    }
}

.quotationModalBody {
    box-sizing: border-box;
    margin-bottom: 5vh;
}

.quotationModalFooter{
    a{
        text-decoration: none;
    }
}

@media screen and (max-width: 960px) {
    .quotationModalContainer {
        width: 100%;
    }
}
