.headlineCarousel {
    background-color: #135040;
    height: 80vh;
    overflow: hidden;
    &__item {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100%;
        &__text {
            box-sizing: border-box;
            flex: 3;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 20px;
            padding: 5vh 2vw;
            color: white;
            &__title {
                height: 30%;
                font-size: 2.8rem;
                font-weight: 500;
            }
            &__text {
                padding-bottom: 20px;
                height: 18%;
                font-size: 1.2rem;
            }
            &__button {
                a {
                    text-decoration: none;
                    button {
                        cursor: pointer;
                    }
                }
            }
        }
        &__image {
            flex: 3;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            transition: background-image 0.2s ease-in-out;
        }
    }
}

@media screen and (max-width: 960px) {
    .headlineCarousel {
        height: 95vh;
        &__item {
            flex-direction: column;
            &__text {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0vh 5vw;
                gap: 1em;
                &__title {
                    height: 30%;
                    font-size: 30px;
                    font-weight: 500;
                }
                &__text {
                    font-size: 12px;
                    font-weight: 400;
                    padding-bottom: 0px;
                }
            }
            &__image {
                flex: 1;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
            }
        }
    }
}
