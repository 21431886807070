.btn {
    display: flex;
    color: #135040;
    background-color: #a3e1c9;
    border: solid transparent;
    border-radius: 10px;
    padding: 1em 1rem;
    font-size: 16px;
    font-weight: 600;
    align-items: center;
    cursor: pointer;
    span {
        margin: 0 0.8em 0 0;
        font-size: 12px;
        border-radius: 100%;
        background-color: #ed1c24;
        color: white;
        padding: 0.3em;
    }
}

.btn:hover {
    background-color: #a3e1c9;
}

.transparent {
    background-color: #a3e1c979;
}
