.contactUsContainer {
    overflow: hidden;
    &__contactInfo {
        padding: 5vh 5vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        &__title {
            font-size: 59px;
            font-weight: bold;
            color: #135040;
        }
        &__image {
            width: 100%;
            height: 50vh;
            border-radius: 10px;
        }
        &__addressSection {
            margin-top: 50px;
            box-sizing: border-box;
            padding: 20px;
            width: 100%;
            display: flex;
            flex-direction: row;
            background-color: #fafafa;
            border-radius: 10px;
            &__address {
                flex: 3;
                font-size: 24px;
                align-self: flex-start;
                color: #6c6c6c;
                font-weight: 500;
                a:link,
                a:visited {
                    color: #135040;
                }
            }
            &__contact {
                flex: 1;
                text-align: right;
                font-size: 24px;
                color: #6c6c6c;
                font-weight: 500;
            }
        }
        &__instagram {
            display: flex;
            font-size: 18px;
            font-weight: 500;
            color: #135040;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            text-decoration: none;
            margin-top: 5vh;
        }
    }
    &__faq {
        margin-top: 10vh;
        &__banner {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            padding: 5vh 5vw;
            box-sizing: border-box;
            height: 55vh;
            background-color: black;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            &__title {
                color: white;
                font-size: 59px;
                font-weight: bold;
            }
            &__subtitle {
                color: white;
                font-weight: 400;
                font-size: 24px;
            }
        }
    }
}

.contactUsButton {
    cursor: pointer;
    background-color: transparent;
    color: #b3b3b3;
    border: none;
    font-size: 24px;
    border-bottom: 3px solid transparent;
    margin: 0px;
    width: 250px;
    padding: 20px;
    box-sizing: border-box;
}

.contactUsButtonActive {
    cursor: pointer;
    background-color: transparent;
    color: #135040;
    font-size: 24px;
    font-weight: 600;
    border: none;
    border-bottom: 3px solid #135040;
    margin: 0px;
    width: 250px;
    padding: 20px;
    transition: border-bottom 0.2s ease-in-out, color 0.2s ease-in-out;
    box-sizing: border-box;
}

.divider {
    margin-top: 0px;
    margin-bottom: 5vh;
    width: 100%;
    border: 1px solid #eeeeee;
    background-color: #eeeeee;
}

@media screen and (max-width: 960px) {
    .contactUsContainer {
        &__contactInfo {
            padding: 5vh 0px;
            &__title {
                font-size: 32px;
            }
            &__image {
                height: 40vh;
                border-radius: 0px;
            }
            &__addressSection {
                width: 90%;
                flex-direction: column;
                background-color: #fafafa;
                &__address {
                    font-size: 14px;
                }
                &__contact {
                    font-size: 14px;
                    align-self: flex-start;
                }
            }
            &__instagram {
                margin-top: 1vh;
                font-size: 14px;
            }
        }
        &__faq {
            margin-top: 3vh;
            &__banner {
                height: 35vh;
                &__title {
                    font-size: 32px;
                }
                &__subtitle {
                    font-size: 14px;
                }
            }
        }
    }

    .contactUsButton {
        font-size: 14px;
        width: 150px;
        padding: 20px;
    }

    .contactUsButtonActive {
        font-size: 14px;
        width: 150px;
        padding: 20px;
    }
}
