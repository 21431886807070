.faqContainer {
    display: flex;
    flex-direction: row;
    padding: 5vh 5vw;
}

.faqTitle {
    flex: 2;
    font-size: 36px;
    font-weight: bold;
    color: #135040;
}

.faqItems {
    flex: 3;
    padding-top: 20px;
}

.faqItemContainer {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e7e7e7;
    overflow: hidden;
    &__upper {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        &__q {
            font-size: 24px;
            font-weight: 600;
            color: #135040;
        }
        &__arrow {
            transition: transform 0.2s ease-in-out;
        }
    }
    &__lower {
        font-size: 16px;
        color: #6c6c6c;
        transition: margin 0.2s linear, height 0.2s linear;
    }
}

@media screen and (max-width: 960px) {
    .faqContainer {
        flex-direction: column;
        align-items: center;
    }

    .faqTitle {
        font-size: 24px;
        text-align: center;
    }

    .faqItemContainer {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #e7e7e7;
        overflow: hidden;
        &__upper {
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            &__q {
                font-size: 16px;
            }
            &__arrow {
                width: 20px;
                height: 20px;
            }
        }
        &__lower {
            font-size: 12px;
        }
    }
}
