.sidebar{
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 60px;
    left: -100vw;
    background-color: white;
    transition: all .5s ease-in-out;
    &__items{
        display: flex;
        flex-direction: column;
        margin-top: 1em;
        &__item{
            margin-left:2em;
            > div{
                display: flex;
                gap: 1em;
                color: #135040;
                font-size: 1.5em;
                font-weight: 400;
            }
         
        }
    }
}

.active{
    left: 0;
}

.sidecard{
    display: flex;
    flex-direction: column;
    margin-left: 1em;
    &__menu{
        text-decoration: none;
        font-size: 0.8em;
        color:#135040;

    }
}

.sidecard-hidden{
    display: none !important;
   
}