.quotation-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    h1 {
        font-weight: 700;
        font-size: 1.5rem;
        color: #135040;
        margin: 2em 0;
    }
    h3 {
        font-weight: 700;
        font-size: 1rem;
        color: #135040
    }
    &__summary{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        background-color: #e6f0ec;
    
        &__header{
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
    &__contact{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        h3{ 
            margin: 2em 0;
        }
        &__form{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            > div{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                width: 80%;
                margin: 1em;
                padding: 1em 1em 0.7em 1em;
                background-color: #f5f5f5;
                border: solid 1px transparent;
                border-radius: 10px;
                gap: 0.5em;
                label{
                    font-size: 0.8rem;
                }
                input{
                    font-size: 1rem;
                    min-height: 1em;
                    background-color: #f5f5f5;
                    border: none;
                    width: 100%;
                }
                input:focus{
                    border: none;
                    outline: none;
                    width: 100%;
                    font-weight: bold;
                }
            }
            > div:focus-within {
                border: #135040;
                outline-color: #135040;
                outline-style: solid;
            }
            button{
                display: flex;
                align-items: center;
                justify-content: center;
                border: solid 1px transparent;
                border-radius: 10px;
                color: white;
                background-color: #135040;
                padding: 1.4em 1em;
                width: 70%;
                gap: 1em;
                font-size: 1em;
                letter-spacing: 1px;
                font-weight: 400;
                img{
                    height:30px;
                }
            }
        }
        &__back{
            
            span{
                font-size: 1.5em;
            }
            a{
                display: flex;
                align-items: center;
                gap: 1em;
                text-decoration: none;
            }
        }
    }
}