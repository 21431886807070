/* Navbar */
.navbar {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: white;
    display: flex;
    width: 100%;
    padding: 1em 0;
    justify-content: space-around;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    > a{
        display: flex;
        align-items: center;
    }
    .hamburger {
        display: none;
    }

    &__items {
        display: flex;
        gap: 4em;
        align-items: center;
        cursor: pointer;

        &__item {
            display: flex;
            flex-direction: column;

            div:first-child {
                display: flex;
                align-items: center;
                gap: 1em;
            }
        }
    }
    &__subitems {
        display: flex;
        justify-content: flex-start;
        width: 40%;
    }
}

@media screen and (max-width: 960px) {
    .navbar {
        width: 100%;
        justify-content: space-between;
        

        .hamburger {
            display: flex;
            align-items: center;
            transition: all ease-in-out 1s;
            img {
                margin-left: 1em;
            }
        }

        &__items {
            display: none;
        }

        button {
            display: none;
        }
        > a {
            margin-right: 1em;
        }
    }


}
