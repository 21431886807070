.nav-card{
    position: absolute;
    z-index: 999;
    top: 65px;
    list-style: none;
    margin: 0;
    padding: 0;
    transition: all 0.5s ease-in-out;
    opacity: 1;
    li{
        background-color: #daf3e9;
        padding: 0.5em 12em 0.5em 1em;
    }
    &__menu{
        color: #000000;
        text-decoration: none;
    }
    &__menu:first-child li{
        padding: 1em 12em 0.5em 1em;
    }
    &__menu:last-child li{
        padding: 0.5em 12em 1.5em 1em;
    }
    
}

.hidden {
    opacity: 0;
}

.nav-card li:hover{
    background-color: #C3E4D7;
}

.nav-card li:focus{
    background-color: #135040;
}