.products {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 10em;
    &__tags{
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 999;
     
        h1 {
            margin: 1em 0 1em 5rem;
        }
        &__tag {
            margin-left: 5em;
            display: flex;
            gap: 1em;
            h3 {
                color: #b3b3b3;
                font-weight: 300;
                padding: 0.8em 1.2em;
                border: solid 1px #d3d1d1;
                border-radius: 10px;
                cursor: pointer;
                font-size: 15px;
            }
            .active-tag {
                background-color: #135040;
                color: white;
            }
        }
    }
    

    &__items {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        min-width: 0;
        gap: 10px;
        padding: 5vw;
        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            min-width: 0;
            box-sizing: border-box;
            border-radius: 10px;
            border: 0px solid #b3b3b3;
            padding: 4px;
            overflow: hidden;
            img {
                max-width: 300px;
            }
            p {
                font-weight: 600;
            }
        }
        &__item:hover {
            cursor: pointer;
            border: 2px solid #b3b3b3;
            padding: 2px;
        }
    }
}

@media screen and (max-width: 960px) {
    .products {
        h1 {
            margin: 1em 0 1em 1rem;
        }
        &__tags {
            &__tag{
                margin-left: 1em;
                flex-wrap: wrap;
                min-width: 0;
            }
           
        }
        &__items {
            grid-template-columns: 1fr 1fr;

            &__item {
                img {
                    max-width: 150px;
                }
                p {
                    font-size: 0.8em;
                }
            }
        }
    }
}
