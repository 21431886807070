.quotation-summary{
    h1{
        display: flex;
        width: 100%;
        justify-content: center;
        color: #135040

    }
    &__container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        flex-wrap: wrap;
        &__header{
            width: 100%;
            h3{
                margin: 0 1em;
            }
            hr{
                border-color: #135040;
                margin: 1em;
            }
        }
        &__content{
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            margin: 2em 0;
            &__item{
                display: flex;
                align-items: flex-start;
                padding-bottom: 2em;
                border-bottom: 1px solid #135040;
                width: 90%; 
                
                > div{
                    width: 50%;
                    > img{
                        max-height: 120px;
                    }
              
                }               
                &__description{
                    display: flex;
                    flex-direction: column;
                    
                    h3{
                        margin: 0;
                    } 
                    > h3:last-child{
                        margin-top: 1em;
                    }
                    p{
                        margin: 0;
                    }
                    button{
                        cursor: pointer;
                        display: flex;
                        align-self: flex-end;
                        justify-content: flex-end;
                        padding: 0;
                        gap: 0.5em;
                        border: none;
                        background-color: transparent;
                    }
                }
                
            }
            &__item:not(:first-child){
                margin-top: 2em;
            }
            &__item:last-child{
                border-bottom: none;
            }
        }
    }
    > img{
        max-height: 120px;
    }
}

@media screen and (min-width: 960px){
    .quotation-summary{
        height: 100vh;
        &__container{
            margin-top: 5em;
            &__header{
                
                h3{
                    font-size: 1.5em;
                    margin: 0;
                    margin-left: 0.5em;
                }
            }
            &__content{
                &__item{
                    justify-content: space-between;
                    gap: 1em;
                    > div:first-child {
                        width: fit-content;
                        img{
                            max-height: 180px;
                        }
                        max-height: 200px;
                    } 
                    &__description{
                        width: 90%;
                        gap: 5em;
                        h3:last-child{
                            margin-top: 1em;
                        }
                    }
                }
            }
            
        }
    }
    
}