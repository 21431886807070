
.title{
    color: #135040;
    font-weight: 700;
    align-items: center;
    font-size: 2em;

}
.product-range{
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 5%;
    align-items: center;

}

.product-placeholder{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.product-type{
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    gap: 1em;

}

.image-placeholder{
    display: flex;
    justify-content: center;
    img{
        border: solid 1px transparent;
        border-radius: 10px;
    }
 
}

.image-placeholder img{
    max-width: 70%;
    max-height: 70%;
}

.product-name{
    flex: 1;
}

a{
    cursor: pointer;
}