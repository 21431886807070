.clientListContainer {
  display: none; //TODO: CHANGE TO FLEX IF YOU WANT TO SHOW THIS
  flex-direction: column;
  gap: 3em;
  align-items: center;
  text-align: center;
  margin-top: 10px;
}

.clientListTitle {
  width: 40%;
  &__title {
    font-size: 36px;
    font-weight: bold;
    color: #135040;
  }
  &__text {
    font-size: 16px;
    color: #7c7c7c;
  }
}

.clientListLogo {
  display: flex;
  flex-direction: row;
  gap: 2em;
  width: 100%;
  height: 100%;
  align-items: center;
  &__item {
    flex: 1;
  }
}
