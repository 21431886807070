.aboutUsContainer {
    display: flex;
    flex-direction: column;
    height: auto;
    overflow: hidden;
}

.bannerContainer {
    width: 100%;
    height: 35vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.ourStoryContainer {
    background-color: #135040;
    color: white;
    padding: 10vh 10vw;
    &__title {
        font-size: 59px;
        font-weight: 600;
    }
    &__description {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        &__title {
            flex: 2;
            font-size: 20px;
        }
        &__description {
            flex: 8;
            font-size: 24px;
            margin-top: 12px;
        }
    }
}

.visionMissionContainer {
    padding: 5vh 10vw;
    &__vision {
        display: flex;
        flex-direction: row;
        &__title {
            flex: 2;
            font-size: 20px;
        }
        &__description {
            flex: 8;
            font-size: 36px;
            margin-top: 12px;
        }
    }
    &__mission {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: stretch;
        &__title {
            flex: 2;
            font-size: 20px;
        }
        &__description {
            flex: 8;
            font-size: 36px;
            margin-top: 12px;
        }
    }
}

.learnMoreContainer {
    color: white;
    background-color: #135040;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6vh 0px;
    font-size: 32px;
    font-weight: 500;
    text-align: center;
}

.whyChooseUsContainer {
    background-color: #e6f0ec;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0px 5vw;
    &__title {
        flex: 1;
        text-align: center;
        font-size: 36px;
        font-weight: bold;
        color: #135040;
    }
}

.imageCarouselContainer {
    background-color: #e6f0ec;
}

.ourCertificationContainer {
    background-color: #e6f0ec;
    display: flex;
    flex-direction: column;
    padding: 5vh 5vw;
    > h1 {
        color: #135040;
    }
    > p {
        color: #7c7c7c;
        font-weight: 600;
    }
}

.certificationCards {
    display: flex;
    flex-direction: row;
    gap: 50px;
    &__card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 5vh 2vw;
        flex: 1;
        background-color: white;
        border-radius: 10px;
        h1 {
            color: #135040;
            font-size: 24px;
        }
        p {
            color: #135040;
            font-weight: 600;
        }
    }
}

// UVP SCSS SECTION
.aboutUsUvpContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 20px;
}

.aboutUsUvpList {
    flex: 1;
    display: flex;
    padding-right: 5vw;
    flex-direction: row;
    gap: 2vw;
    &__title {
        font-weight: bold;
        font-size: 24px;
        color: #135040;
    }
    &__text {
        font-size: 15px;
        font-weight: 600;
        color: #7c7c7c;
    }
}
// END OF UVP SCSS SECTION ======

@media screen and (max-width: 960px) {
    .whyChooseUsContainer {
        flex-direction: column;
    }

    .bannerContainer {
        height: 35vh;
    }

    .ourStoryContainer {
        padding: 5vh 10vw;
        &__title {
            font-size: 28px;
        }
        &__description {
            flex-direction: column;
            justify-content: flex-start;
            &__title {
                font-size: 14px;
            }
            &__description {
                padding-left: 5vw;
                font-size: 16px;
            }
        }
    }

    .visionMissionContainer {
        padding: 5vh 10vw;
        &__vision {
            flex-direction: column;
            &__title {
                flex: 2;
                font-size: 14px;
            }
            &__description {
                flex: 8;
                padding-left: 5vw;
                font-size: 18px;
            }
        }
        &__mission {
            flex-direction: column;
            &__title {
                flex: 2;
                font-size: 14px;
            }
            &__description {
                flex: 8;
                padding-left: 5vw;
                font-size: 18px;
            }
        }
    }
    .learnMoreContainer {
        padding: 5vh 0px;
        font-size: 24px;
    }

    .certificationCards {
        flex-direction: column;
        align-items: center;
        &__card {
            align-items: center;
            width: 75%;
            flex: 1;
            text-align: center;
        }
    }
    .whyChooseUsContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 5vh 5vw;
        gap: 20px;
    }

    .aboutUsUvpList {
        flex: 1;
        text-align: left;
        &__title {
            font-weight: bold;
            font-size: 24px;
            color: #135040;
        }
        &__text {
            font-size: 14px;
            font-weight: 600;
            color: #7c7c7c;
        }
    }
}
