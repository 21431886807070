.gallery{
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 5em 0;
    &__container{
        width: 50%;
        img{
            max-height: 350px;
            object-fit: cover;
        }
    }
}

.carousel{
    display: flex;
    align-items: center;
}

.arrow{
    max-width: 50px;
}

@media screen and (max-width: 960px) {
    .gallery{
        
        &__container{
            width: 80%;
            img{
                max-height: 180px;
                object-fit: cover;
            }
        }
    }
    
}
