input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    border: none;
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    border: none;
    -moz-appearance:textfield; /* Firefox */
}

.quotationModalItemContainer {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    color: #135040;
    padding: 1vh 1vw;
    gap: 20px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba($color: #135040, $alpha: 0.3);
}

.quotationModalItemInformation {
    flex: 1.5;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0px;
    &__category {
        font-size: 14px;
        margin: 0px;
    }
    &__name {
        font-size: 20px;
        font-weight: 600;
        margin: 0px;
    }
    &__quantityText {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 2vh;
    }
    &__quantity {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        &__plusmin {
            display: flex;
            flex-direction: row;
            align-items: center;
            border: 1px solid #135040;
            padding: 1vh 1vw;
            border-radius: 6px;
            box-sizing: border-box;
            margin: 0px;
        }
        &__remove {
            &__btn {
                display: flex;
                flex-direction: row;
                background-color: transparent;
                border: none;
                cursor: pointer;
                font-size: 14;
                color: #135040;
                gap: 10px;
            }
        }
    }
}

.quotationModalItemImage {
    flex: 1;
}

.qtyButton {
    background-color: #135040;
    color: white;
    width: 25px;
    height: 25px;
    font-size: 16px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
}

.productUnits {
    width: 7vw;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    background-color: transparent;
}
