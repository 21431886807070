input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    border: none;
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    border: none;
    -moz-appearance:textfield; /* Firefox */
}

.navigation {
    text-decoration: none;
    color: #135040;
}
.productsDetailContainer {
    overflow: hidden;
    padding: 5vw;
}

.productsDetailSection {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 5vw;
    &__image {
        flex: 1;
        display: flex;
        overflow: hidden;
        box-sizing: border-box;
        border-radius: 10px;
        border: 1px solid #d9d9d9;
        img {
            display: block;
            margin: auto;
            width: 100%;
        }
    }
    &__specs {
        flex: 1;
        &__title {
            font-weight: 600;
            font-size: 48px;
            color: #135040;
        }
        &__subtitle {
            font-weight: 600;
            font-size: 18px;
        }
        &__quotation {
            display: flex;
            flex-direction: row;
            gap: 2vw;
            &__qtySection {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border: 1px solid #e7e7e7;
                border-radius: 10px;
                padding: 5px;
                &__units {
                    margin: 0px 25px;
                    width: 75px;
                    text-align: center;
                    font-size: 20px;
                }
            }
        }
    }
}

.keyValue {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 0px 2vw;
    border-top: 2px solid #e7e7e7;
    overflow: hidden;
    text-overflow: ellipsis;
    p {
        flex: 1;
    }
}

.qtyBtn {
    color: #135040;
    width: 40px;
    height: 40px;
    font-size: 24px;
    background-color: #dbf1eb;
    border-radius: 8px;
    border: none;
    cursor: pointer;
}

.quoteBtn {
    background-color: #135040;
    padding: 1vh 5vw;
    font-size: 24px;
    color: white;
    font-weight: 600;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}

@media screen and (max-width: 640px) {
    .navigation {
        text-decoration: none;
        color: #135040;
    }
    .productsDetailContainer {
        overflow: hidden;
        padding: 5vw;
    }

    .productsDetailSection {
        flex-direction: column;
        &__image {
            flex: 1;
            img {
                width: 100%;
            }
        }
        &__specs {
            flex: 1;
            &__title {
                font-size: 36px;
            }
            &__subtitle {
                font-size: 18px;
            }
            &__quotation {
                display: flex;
                flex-direction: row;
                gap: 1vw;
                &__qtySection {
                    &__units {
                        margin: 0px 25px;
                        width: 75px;
                        text-align: center;
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .qtyBtn {
        width: 30px;
        height: 30px;
        font-size: 20px;
    }

    .quoteBtn {
        background-color: #135040;
        padding: 1vh 3vw;
        font-size: 18px;
    }
}
